import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import * as firebase from 'firebase/app';
// import 'firebase/firestore'; wtf is this?

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showMenu = "";
  title = "b.othercooked.com";
  items: any;
  currentBoardName = "";//Administrator";
  currentBoardId = "";
  language = "en";
  history = false;
  urlData: any;
  place: any = {};
  placeName = "";
  placeType = "";
  logged = null;
  password: any;
  page = "front";
  boards = [];
  currentTable: any;
  Baskets: any;// = [];
  BasketsNumbers = {};
  ordersCollection = "Orders";
  placeId = "";
  boardId = "";

  constructor(public db: AngularFirestore, public auth: AngularFireAuth,) {

    console.log("1456 :)");



  }


  async ngOnInit() {


    this.placeId = window.location.pathname.split('/')[1];
    console.log(this.placeId);

    if (!this.placeId) {
      this.page == "front";
      return;
    }

    if (this.placeId == "app") {
      this.page = "app";
      return;
    }

    // big else here 
    this.page = "board";

    this.db.doc('Places/' + this.placeId).ref.get().then(snap => {
      if (snap.exists) {
        var data: any = snap.data();
        this.place = data;
        this.placeName = data.Name;
        this.placeType = data.Type;
      }
    });

  }


  BoardChange(id, name) {
    this.currentBoardName = name;
    this.currentBoardId = id;
  }



  async Login() {
    console.log(this.password + "/" + this.placeId);

    if (!this.password)
      return;

    // new way
    // sigin in with email and pass MOVE THIS TO PROPER AUTH
    var auth = await this.auth.signInWithEmailAndPassword(this.placeId.toLowerCase() + "-board@othercooked.com", this.password).then(u => {
      console.log("LOGGED! " + u.user.uid);
      this.boardId = u.user.uid;
      return u;
    }).catch(e => {
      console.log("failed");
      return null;
    });

    // return if failed
    if (auth == null) {
      console.log("failed");
      return;
    }



    this.logged = "Administrator";

    this.BoardChange("Administrator", "Administrator");

    if (this.currentBoardId == "Administrator") {
      await this.db.collection('Places/' + this.placeId + '/Boards').ref.get()
        .then(snap => {
          this.boards = [];
          for (var b of snap.docs) {
            var bData: any = b.data();
            this.boards.push({ id: b.id, name: bData.Name, color: bData.Color });
          }
        });

      this.Baskets = await this.db.collection('Places/' + this.placeId + '/Baskets', ref => ref.orderBy('Number'))
        .snapshotChanges().pipe(
          map(table => table.map(a => {
            var jopa: any = a.payload.doc.data();
            jopa.id = a.payload.doc.id;

            this.BasketsNumbers[jopa.id] = jopa.Number;

            // https://www.materialui.co/colors
            if (jopa.Status == "Call") jopa.Color = "#FF8A65";//"warn";
            if (jopa.Status == "Free") jopa.Color = "#E0E0E0";//"link"; // 300 opacity
            if (jopa.Status == "Reserved") jopa.Color = "#FFD54F";//"accent";

            return jopa;
          })
          )
        );

    }

  }

  Logout() {
    this.logged = null;
    this.password = null;
    this.currentBoardName = null;
  }

  Test() {
    if (confirm("Are you sure to delete?")) {
      console.log("ok");
    }
  }

  GetUsersAmount(table) {
    if (table && table.Users)
      return Object.keys(table.Users).length;
    else return 0;
  }

  StatusChangeOnproduct(order, product, index, newStatus) {
    // Process, Ready, Done, Delete

    if (newStatus == "New") {
      return;
    }

    if (newStatus == "Delete") {
      if (confirm("Are you sure to delete " + order.Products[index].Name + "?")) {
        console.log("delete...");
        console.log("was " + order.Products[index].Price + "/" + order.Total);

        // revert amount FIRSTLY because later we delete this product
        if (order.Products[index].Quantity) {
          console.log("revert " + order.Products[index].ProductId + " of " + order.Products[index].Quantity);
          this.db.doc('Places/' + this.placeId + '/Products/' + order.Products[index].ProductId).set({
            Available: firebase.firestore.FieldValue.increment(order.Products[index].Quantity),
            Reserved: firebase.firestore.FieldValue.increment(-order.Products[index].Quantity)
          }, { merge: true });
        }

        // get product's price
        var price = order.Products[index].Price;

        // change order's total on this price
        var newTotal = order.Total - price;

        // delete product from array
        var newProducts = order.Products;
        newProducts.splice(index, 1);



        // save order in Global
        this.db.doc(this.ordersCollection + '/' + order.OrderId).set({
          Total: newTotal,
          NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
          Products: newProducts
        }, { merge: true });

        // basket counters
        this.db.doc('Places/' + this.placeId + '/Baskets/' + order.Place.BasketId).set({
          NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
          // orders? no?
        }, { merge: true });

        return;
      }
      else {
        return;
      }
    }

    if (newStatus == "Done") {
      // basket counters
      this.db.doc('Places/' + this.placeId + '/Baskets/' + order.Place.BasketId).set({
        NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        // orders? no?
      }, { merge: true });
    }



    // this product in Orders
    var newProducts = order.Products;
    newProducts[index].Status = newStatus;

    // this product in Global
    this.db.doc(this.ordersCollection + '/' + order.OrderId).set({
      Products: newProducts,
      Status: "Process",
      NotDoneProducts: (newStatus == "Done") ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0),
    }, { merge: true });

  }

  TableChange(t = null) {
    console.log("TABLE changed " + t.Name + " " + this.history);
    console.log("Query: " + this.ordersCollection + " " + t.id);

    if (t) {
      this.currentTable = t;

      if (this.history) // show only paid (OLD!)
        this.items = this.db.collection(this.ordersCollection, ref => ref
          .where("Place.PlaceId", "==", this.placeId)
          .where("Place.BoardId", "==", this.boardId)
          .where("Place.BasketId", "==", this.currentTable.id)
          .where("Status", "in", ["Paid", "Cancelled"])
          .orderBy('Created', 'desc')
          .limit(30)
        ).snapshotChanges()
          .pipe(

            map(restaurant => restaurant.map(a => {

              var jopa: any = a.payload.doc.data();
              if (jopa.Status != "Paid")  // was Done
                this.currentTable.HasOrders = true;

              return jopa;

            }))
          );
      else // show only work ones
        this.items = this.db.collection(this.ordersCollection, ref => ref
          .where("Place.PlaceId", "==", this.placeId)
          .where("Place.BoardId", "==", this.boardId)
          .where("Place.BasketId", "==", this.currentTable.id)
          .where("Status", "not-in", ["Paid", "Cancelled"])
          .orderBy('Status', 'desc')
          .orderBy('Created', 'desc')
        ).snapshotChanges()
          .pipe(

            map(results => results.sort((a, b) => {

              var jopaA: any = a.payload.doc.data();
              var jopaB: any = b.payload.doc.data();
              // return new Date(jopaA.Created).getTime() - new Date(jopaB.Created).getTime();
              return (jopaA.OrderId - jopaB.OrderId);
            })),

            map(restaurant => restaurant.map(a => {

              var jopa: any = a.payload.doc.data();
              if (jopa.Status != "Paid")  // was Done
                this.currentTable.HasOrders = true;

              // console.log(jopa.User.Name);
              // delete jopa.showMenu;

              return jopa;

            }))
          );


    }


  }


  About() {
    return alert(" \r\n\
    Build 20240331.1200 \r\n\
    Whats new: \r\n\
    - cash calc fix \r\n\
    - price .00 \r\n\
    ");
  }

  GetColorOfTheBoard(id) {
    return this.boards.find(element => element.id == id).color;
  }


  RecoverOrder(order, recover) {

    this.db.doc(this.ordersCollection + '/' + order.OrderId).set({
      Status: (recover) ? "Recovered" : "Paid"
    }, { merge: true });

  }

  async FreeTable(table) {
    // console.log(tableId);

    delete this.currentTable.HasOrders;
    // console.log(this.currentTable);

    await this.db.doc('Places/' + this.placeId + '/Baskets/' + table.id).set({
      Status: "Free",
      NotDoneOrders: 0,
      NotDoneProducts: 0,
      Users: firebase.firestore.FieldValue.delete()
    }, { merge: true });


    this.TableChange();
  }

  async CallTable(table) {
    // console.log(tableId);

    await this.db.doc('Places/' + this.placeId + '/Baskets/' + table.id).set({
      Status: "Reserved"
    }, { merge: true });

    this.currentTable.Status = "Reserved";
    this.TableChange(table);
  }

  pMenuShow(p) {
    if (this.showMenu != "" && this.showMenu != p.PID) {
      console.log("NOT ONLY ONE!");
      return;
    }
    // console.log("menu on");
    p.showMenu = true;
    this.showMenu = p.PID;
    // console.log(this.showMenu);
  }
  pMenuClose(p) {
    // console.log("menu off");
    delete p.showMenu;// = false;
    this.showMenu = "";
    // console.log(this.showMenu);
  }

  ProductDelete(order, index) {
    this.pMenuClose(order.Products[index]);

    if (confirm("Are you sure to delete " + order.Products[index].Name + "?")) {
      console.log("delete...");
      console.log("was " + order.Products[index].Price + "/" + order.Total);

      // revert amount FIRSTLY because later we delete this product
      if (order.Products[index].Quantity) {
        console.log("revert " + order.Products[index].ProductId + " of " + order.Products[index].Quantity);
        this.db.doc('Places/' + this.placeId + '/Products/' + order.Products[index].ProductId).set({
          Available: firebase.firestore.FieldValue.increment(order.Products[index].Quantity),
          Reserved: firebase.firestore.FieldValue.increment(-order.Products[index].Quantity)
        }, { merge: true });
      }

      // get product's price
      var price = order.Products[index].Price;

      // change order's total on this price
      var newTotal = order.Total - price;

      // delete product from array
      var newProducts = order.Products;
      newProducts.splice(index, 1);



      // save order in Global
      this.db.doc(this.ordersCollection + '/' + order.Id).set({
        Total: newTotal,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        Products: newProducts
      }, { merge: true });

      // basket counters
      // this.db.doc('Places/'+this.placeId+'/Baskets/'+order.Place.BasketId).set({
      //   NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
      //   // orders? no?
      // }, {merge: true});

      this.CheckIsOrderDone(newProducts, order.Id);

      return;
    }
    else {
      return;
    }

  }

  ProductNextStatus(order, index, isDone) {
    // New, Process, Ready, Done


    var newStatus = "";

    if (isDone)
      newStatus = "Done";
    else {
      if (order.Products[index].Status == "New")
        newStatus = "Process";
      else if (order.Products[index].Status == "Process")
        newStatus = "Ready";
      else if (order.Products[index].Status == "Ready")
        newStatus = "Done";
    }

    this.pMenuClose(order.Products[index]);


    // if (newStatus == "Done") {
    //   // basket counters
    //   this.db.doc('Places/'+this.placeId+'/Baskets/'+order.Place.BasketId).set({
    //     NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
    //     // orders? no?
    //   }, {merge: true});
    // }



    // this product in Orders
    var newProducts = order.Products;
    newProducts[index].Status = newStatus;

    this.CheckIsOrderDone(newProducts, order.Id);

  }


  CheckIsOrderDone(newProducts, id) {
    // check all products
    var isOrderDone = true;
    newProducts.forEach(p => {
      if (p.Status != "Done")
        isOrderDone = false;
    });
    console.log("is order done? " + isOrderDone);

    // this product in Global
    this.db.doc(this.ordersCollection + '/' + id).set({
      Products: newProducts,
      Status: (isOrderDone == true) ? "Done" : "Process",
      // NotDoneProducts: (newStatus == "Done") ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0),
    }, { merge: true });
  }

  ToggleHistory() {
    this.history = !this.history;
    this.TableChange(this.currentTable);
  }


}
