import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as firebase from 'firebase/app';
import { AppComponent } from '../app.component';
import { CdkDragDrop, CdkDragEnter, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input()
  order: any = {};

  @Input()
  place: any = {};

  @Input()
  language: string = "en";

  @Input()
  board: string = "";

  @Input()
  boards = [];

  // local stuff
  email;
  showMenu = "";
  showMenu2 = {};
  showMenu3 = false;
  cash1 = 0;
  cash2;

  ordersCollection = "Orders";

  finalStage = 0;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(public db: AngularFirestore) { }

  ngOnInit(): void {
    console.log("redraw order...");
  }

  pIsMenuOpen(p) {
    // return this.showMenu2[m.PID];
    return p.showMenu;
  }
  pMenuShow(p) {
    // console.log("pMenuShow");
    p.showMenu = true;
    // this.showMenu2[p.PID] = true;
    // this.showMenu3 = true;
    // console.log("pMenuShow " + this.showMenu + "/" + p.showMenu);
    // if (this.showMenu != "" && this.showMenu != p.PID) {
    //   console.log("NOT ONLY ONE!");
    //   return;
    // }
    // // console.log("menu on");
    // p.showMenu = true;
    // this.showMenu = p.PID;
    // // console.log(this.showMenu);
  }
  pMenuClose(p, event = null) {
    // console.log("pMenuClose");
    delete p.showMenu;
    // this.showMenu3 = false;
    // this.showMenu2[p.PID] = false;
    if (event) event.stopPropagation();
    // delete p.showMenu;
    // p.showMenu = false;
    // console.log("pMenuClose");
    // delete p.showMenu;// = false;
    // this.showMenu = "";
    // // console.log(this.showMenu);
  }

  GetColorOfTheBoard(id) {
    var el = this.boards.find(element => element.id == id);
    if (el) return el.color;
    else
      return "#000000";
    //return this.boards.find(element => element.id == id).color;
  }

  ProductDelete(index) {
    this.pMenuClose(this.order.Products[index]);

    if (!confirm("Are you sure to delete " + this.order.Products[index].Name + "?"))
      return;


    console.log("delete...");
    console.log("was " + this.order.Products[index].Price + "/" + this.order.Total);

    // revert amount FIRSTLY because later we delete this product
    if (this.order.Products[index].Quantity) {
      console.log("revert " + this.order.Products[index].ProductId + " of " + this.order.Products[index].Quantity);
      this.db.doc('Places/' + this.order.Place.PlaceId + '/Products/' + this.order.Products[index].ProductId).update({
        Available: firebase.firestore.FieldValue.increment(this.order.Products[index].Quantity),
        // Reserved: firebase.firestore.FieldValue.increment(-this.order.Products[index].Quantity)
      });
    }

    // get product's price
    var price = this.order.Products[index].Price * this.order.Products[index].Quantity; // was without quantity!

    // get last prod state (for next steps)
    var curStatus = this.order.Products[index].Status;

    // change order's total on this price
    // var newTotal = parseFloat((this.order.Total - price).toFixed(2));
    var newTotal = this.order.Total - price;
    console.log("DELETE price update: was " + this.order.Total + " - " + price + " = " + newTotal);

    // delete product from array
    var newProducts = this.order.Products;
    newProducts.splice(index, 1);



    // save order in Global
    this.db.doc(this.ordersCollection + '/' + this.order.Id).update({
      Total: newTotal,
      // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
      Products: newProducts
    });

    // basket counters ONLY IF THIS PRODUCT WASNT DONE! otherwise it will -- twice, once Done and once deleted
    if (curStatus != "Done")
      this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).update({
        NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        // orders? no?
      });

    this.CheckIsOrderDone(newProducts, this.order.Id);

    // return;    
  }

  ProductNextStatus(index, isDone) {
    // New, Process, Ready, Done


    var newStatus = "";

    if (isDone)
      newStatus = "Done";
    else {
      if (this.order.Products[index].Status == "New")
        newStatus = "Process";
      else if (this.order.Products[index].Status == "Process")
        newStatus = "Ready";
      else if (this.order.Products[index].Status == "Ready")
        newStatus = "Done";
    }

    this.pMenuClose(this.order.Products[index]);


    if (newStatus == "Done") {
      this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).update({
        NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
        // orders? no?
      });
    }



    // this product in Orders
    var newProducts = this.order.Products;
    newProducts[index].Status = newStatus;

    this.CheckIsOrderDone(newProducts, this.order.Id);

  }

  CheckIsOrderDone(newProducts, id) {
    // check all products
    var isOrderDone = true;
    newProducts.forEach(p => {
      if (p.Status != "Done")
        isOrderDone = false;
    });
    console.log("is order done? " + isOrderDone);

    // this product in Global
    this.db.doc(this.ordersCollection + '/' + id).set({
      Products: newProducts,
      Status: (isOrderDone == true) ? "Done" : "Process",
      // NotDoneProducts: (newStatus == "Done") ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0),
    }, { merge: true });

    // basket counters
    // ORDERS-- not here but on payment stage!
    // this.db.doc('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId).update({
    //   // NotDoneProducts: firebase.firestore.FieldValue.increment(-1), NOT HERE!!!
    //   NotDoneOrders: (isOrderDone == true) ? firebase.firestore.FieldValue.increment(-1) : firebase.firestore.FieldValue.increment(0), 
    //   // orders? no?
    // });

  }

  RevertOrder(id) {
    console.log("REVERT! " + id);

    this.db.doc(this.ordersCollection + '/' + this.order.Id).update({
      "Status": "Done",
    });
  }


  DoneOrder() {
    this.finalStage = 1;
  }

  YesReceipt() {

    if (!this.order.User.Email)
      this.finalStage = 2;
    else
      this.finalStage = 3;
  }

  NoReceipt() {

    this.finalStage = 3;
  }

  async EmailSave() {

    this.finalStage = 3;
    console.log("email save done");
  }

  OrderPaidCash() {
    this.finalStage = 4;
  }

  async OrderPaidCard() {
    await this.OrderPaid("PaidByCard");
  }

  async OrderPaid(paymentType) {

    // reports need for All cases!
    this.order.Products.forEach(p => {

      // kassa sync
      if (p.OuterId != "Delivery" && this.place.Type == "Grocery")
        this.db.collection('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Sync/').add({ "Id": p.OuterId, "Quantity": p.Quantity });

      // we did it before on order stage as I remember!?
      // this.db.doc('Places/'+this.placeId+'/Products/'+p.ProductId).set({
      //   // Available: firebase.firestore.FieldValue.increment(p.Quantity),
      //   Reserved: firebase.firestore.FieldValue.increment(-p.Quantity)
      // }, {merge: true});

      // need fix here probably after new prices feature, but we do not need reports at all
      // // save report (do we need separate report or just use Orders later?)
      // var today = new Date();
      // var todayDoc = today.toISOString().substring(0, 10);
      // this.db.doc('Places/' + this.order.Place.PlaceId + '/Reports/' + todayDoc).set({
      //   [paymentType]: firebase.firestore.FieldValue.increment(1),
      //   [p.OuterId]: {
      //     Code: p.OuterId,
      //     Price: p.Price,
      //     Names: p.Names,
      //     Quantity: firebase.firestore.FieldValue.increment(parseInt(p.Quantity)),
      //     VAT: p.VAT,
      //     //Total: firebase.firestore.FieldValue.increment(parseFloat((parseInt(p.Quantity) * p.Price).toFixed(2)))
      //     Total: firebase.firestore.FieldValue.increment(p.Quantity * p.Price)
      //   }
      // }, { merge: true });


      // // for all extras save reports as well
      // if (p.Extras && p.Extras.length > 0) {
      //   p.Extras.forEach(e => {
      //     if (e.OuterId && e.VAT && e.Quantity)
      //       this.db.doc('Places/' + this.order.Place.PlaceId + '/Reports/' + todayDoc).set({
      //         [e.OuterId]: {
      //           Code: e.OuterId,
      //           Price: e.Price,
      //           Names: e.Names,
      //           Quantity: firebase.firestore.FieldValue.increment(parseInt(e.Quantity)),
      //           VAT: e.VAT,
      //           // Total: firebase.firestore.FieldValue.increment(parseFloat((parseInt(e.Quantity) * e.Price).toFixed(2)))
      //           Total: firebase.firestore.FieldValue.increment(e.Quantity * e.Price)
      //         }
      //       }, { merge: true });
      //   });
      // }


    });

    // change status in orders
    if (this.email)
      this.db.doc(this.ordersCollection + '/' + this.order.Id).update({
        "Status": "Paid",
        "User.Email": this.email,
        "PaidBy": paymentType
      });
    else
      this.db.doc(this.ordersCollection + '/' + this.order.Id).update({
        "Status": "Paid",
        "PaidBy": paymentType
      });


    // var userToDelete = this.order.User.UserId;


    // remove user and possible order
    await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Users/' + this.order.User.UserId).delete();
    await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Orders/' + this.order.User.UserId).delete();

    // delete from open orders for this user
    console.log("Delete open order");
    this.db.doc('Users/' + this.order.User.UserId).update({
      [`OpenOrders.${this.order.Place.PlaceId}`]: firebase.firestore.FieldValue.delete(),
    });

    // update basket only if restaurant
    if (this.place.Type == "Restaurant") {

      // --
      await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).update({
        NotDoneOrders: firebase.firestore.FieldValue.increment(-1),
      });

      // compare and free
      await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).ref.get().then(async snap => {

        // if no more orders
        var data: any = snap.data();
        if (data.NotDoneOrders <= 0) {
          console.log("DELETE users " + this.order.User.UserId);

          // free table
          await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).set({
            Status: "Free",
            NotDoneOrders: 0,
            NotDoneProducts: 0,
            // Users: firebase.firestore.FieldValue.delete(), // <- old
          }, { merge: true });

        }

      });


    }



  }

  async CalcCash() {
    if (this.cash2)
      await this.OrderPaid("PaidByCash");
    else
      // this.cash2 = (this.cash1 - this.order.Total).toFixed(2);
      this.cash2 = (this.cash1 * 100) - this.order.Total;
  }




  async CancelOrder(paymentType = null) {

    if (!confirm("Are you sure to CANCEL order ?"))
      return;

    if (!paymentType)
      paymentType = "Cancelled";

    // reports need for All cases!
    this.order.Products.forEach(p => {

      // kassa sync
      if (p.OuterId != "Delivery" && this.place.Type == "Grocery")
        this.db.collection('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Sync/').add({ "Id": p.OuterId, "Quantity": p.Quantity });

      // we did it before on order stage as I remember!?
      // this.db.doc('Places/'+this.placeId+'/Products/'+p.ProductId).set({
      //   // Available: firebase.firestore.FieldValue.increment(p.Quantity),
      //   Reserved: firebase.firestore.FieldValue.increment(-p.Quantity)
      // }, {merge: true});

      // save report (do we need separate report or just use Orders later?)
      var today = new Date();
      var todayDoc = today.toISOString().substring(0, 10);
      this.db.doc('Places/' + this.order.Place.PlaceId + '/ReportsCancelled/' + todayDoc).set({
        [paymentType]: firebase.firestore.FieldValue.increment(1),
        [p.OuterId]: {
          Code: p.OuterId,
          Price: p.Price,
          Names: p.Names,
          Quantity: firebase.firestore.FieldValue.increment(parseInt(p.Quantity)),
          VAT: p.VAT,
          //Total: firebase.firestore.FieldValue.increment(parseFloat((parseInt(p.Quantity) * p.Price).toFixed(2)))
          Total: firebase.firestore.FieldValue.increment(p.Quantity * p.Price)
        }
      }, { merge: true });

      // for all extras save reports as well
      if (p.Extras && p.Extras.length > 0) {
        p.Extras.forEach(e => {
          if (e.OuterId && e.VAT && e.Quantity)
            this.db.doc('Places/' + this.order.Place.PlaceId + '/ReportsCancelled/' + todayDoc).set({
              [e.OuterId]: {
                Code: e.OuterId,
                Price: e.Price,
                Names: e.Names,
                Quantity: firebase.firestore.FieldValue.increment(parseInt(e.Quantity)),
                VAT: e.VAT,
                //Total: firebase.firestore.FieldValue.increment(parseFloat((parseInt(e.Quantity) * e.Price).toFixed(2)))
                Total: firebase.firestore.FieldValue.increment(e.Quantity * e.Price)
              }
            }, { merge: true });
        });
      }


    });

    // status
    this.db.doc(this.ordersCollection + '/' + this.order.Id).update({
      "PaidBy": paymentType,
      "Status": "Cancelled",
    });

    // delete from open orders for this user
    console.log("Delete open order");
    this.db.doc('Users/' + this.order.User.UserId).update({
      [`OpenOrders.${this.order.Place.PlaceId}`]: firebase.firestore.FieldValue.delete(),
    });


    // remove user and possible order
    await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Users/' + this.order.User.UserId).delete();
    await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId + '/Orders/' + this.order.User.UserId).delete();


    // update basket only if restaurant
    if (this.place.Type == "Restaurant") {

      // --
      await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).update({
        NotDoneOrders: firebase.firestore.FieldValue.increment(-1),
      });

      // compare and free
      await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).ref.get().then(async snap => {

        // if no more orders
        var data: any = snap.data();
        if (data.NotDoneOrders <= 0) {
          console.log("DELETE users " + this.order.User.UserId);

          // free table
          await this.db.doc('Places/' + this.order.Place.PlaceId + '/Baskets/' + this.order.Place.BasketId).set({
            Status: "Free",
            NotDoneOrders: 0,
            NotDoneProducts: 0,
            // Users: firebase.firestore.FieldValue.delete(), // <- old
          }, { merge: true });

          // cutted from here!
          // await this.db.doc('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Users/'+this.order.User.UserId).delete();
          // await this.db.doc('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Orders/'+this.order.User.UserId).delete();

          // remove users
          // await this.db.collection('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Users').ref.get().then(snap => {
          //   snap.docs.forEach(doc => {
          //     this.db.doc('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Users/'+doc.id).delete();
          //   });
          // });

          // // remove possible old orders
          // await this.db.collection('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Orders').ref.get().then(snap => {
          //   snap.docs.forEach(doc => {
          //     this.db.doc('Places/'+this.order.Place.PlaceId+'/Baskets/'+this.order.Place.BasketId+'/Orders/'+doc.id).delete();
          //   });
          // });
        }

      });


    }

  }








  //---------------

  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep',
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];

  done = [

  ];


  async drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      console.log('Transfering in SAME container');
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log('From ' + event.previousContainer.id);
      console.log(event.previousContainer.data);
      console.log(event.previousIndex);
      console.log('to ' + this.order.Id);
      console.log(event.container.data);
      console.log(event.currentIndex);
      console.log("object:");
      console.log(event.item.data);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);


      // prepare sum
      //var prodSum = parseFloat((event.item.data.PriceTotal * event.item.data.Quantity).toFixed(2));
      var prodSum = event.item.data.PriceTotal * event.item.data.Quantity;
      //console.log(prodSum);

      // remove and recalc from old
      var oldOrder = await this.db.doc(this.ordersCollection + '/' + event.previousContainer.id).ref.get().then(async (snap: any) => {
        var newProducts = snap.data().Products;  // remove
        newProducts.splice(event.previousIndex, 1);
        //var newTotal = parseFloat((snap.data().Total - prodSum).toFixed(2));    // minus
        var newTotal = snap.data().Total - prodSum;    // minus

        // maybe add here revert available product as in DeleteProduct
        // ...

        // update order
        await this.db.doc(this.ordersCollection + '/' + event.previousContainer.id).set({
          Total: newTotal,
          Products: newProducts
        }, { merge: true });

        // DO NOT ADD BASKET COUNTER! since it is not removing product but transferring!

        this.CheckIsOrderDone(newProducts, event.previousContainer.id);
      });


      // add and recalc from new
      var newOrder = await this.db.doc(this.ordersCollection + '/' + this.order.Id).ref.get().then(async (snap: any) => {
        var newProducts = snap.data().Products.concat(event.item.data);  // add
        //var newTotal = parseFloat((snap.data().Total + prodSum).toFixed(2));    // plus
        var newTotal = snap.data().Total + prodSum;    // plus

        // maybe add here revert available product as in DeleteProduct
        // ...

        // update order
        await this.db.doc(this.ordersCollection + '/' + this.order.Id).set({
          Total: newTotal,
          Products: newProducts
        }, { merge: true });

        // DO NOT ADD BASKET COUNTER! since it is not removing product but transferring!

        this.CheckIsOrderDone(newProducts, this.order.Id);
      });
    }
  }

  // entered(event: CdkDragEnter<any>) {
  //   console.log('Entered', event.item.data);
  //  }

  //  exited(event: CdkDragExit<any>) {
  //    console.log('Exited', event.item.data);
  //  }

  QtyChange(event, index, product, order) {


    // console.log(event);
    if (!event || event == "") {
      console.log("empty, quit");
      return;
    }
    event = Number.parseInt(event);
    var wasQ = product.Quantity;
    var newQ = event;

    if (newQ != wasQ) {// means not the same quantity

      // get product's price
      var price = product.Price;//order.Products[index].Price;

      // change product quantity
      order.Products[index].Quantity = newQ;
      var newProducts = order.Products;

      // recalc total
      var newTotal = 0;
      order.Products.forEach(p => {
        //newTotal += parseFloat((p.Price * p.Quantity).toFixed(2));
        newTotal += p.Price * p.Quantity;
      });
      console.log("New total " + newTotal);

      // change order's total on this price
      // var newTotal = order.Total - (price*event);
      // newTotal = parseFloat((newTotal).toFixed(2));
      // order.Products[index].Quantity = event;
      // var newProducts = order.Products;
      // console.log(order.Total, price*event, newTotal);

      // put back quantity to the shop 5->4 = 1, 5>6 
      // 1->2 
      // console.log(wasQ, newQ, wasQ > newQ);
      var newA, newR;

      if (wasQ > newQ) {
        var x = wasQ - newQ;
        newA = firebase.firestore.FieldValue.increment(x);
        newR = firebase.firestore.FieldValue.increment(-x);
      }
      else {
        var x = newQ - wasQ;
        newA = firebase.firestore.FieldValue.increment(-x);
        newR = firebase.firestore.FieldValue.increment(x);
      }
      // var qtyBack = product.Quantity - event;
      // console.log(qtyBack);
      this.db.doc('Places/' + order.Place.PlaceId + '/Products/' + product.OuterId).update({
        // Available: firebase.firestore.FieldValue.increment(qtyBack),
        // Reserved: firebase.firestore.FieldValue.increment(-qtyBack)
        Available: newA,
        Reserved: newR
      });


      // whole order in Global
      this.db.doc(this.ordersCollection + '/' + order.Id).set({
        Total: newTotal,
        Products: newProducts,
        // NotDoneProducts: firebase.firestore.FieldValue.increment(-1),
      }, { merge: true });

    }
  }

}
